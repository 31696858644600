import { useState, useEffect, useRef, useMemo } from "react";
import "../App.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import mobileImg from "../assets/mobile.svg";
import { roadmap } from "../utils/constant";
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

export default function RoadMap() {
  const roadRef = useRef(null);
  const scrolledVerticalRef = useRef(null);

  useEffect(() => {
    if (scrolledVerticalRef.current) {
      scrolledVerticalRef.current.scrollTop =
        scrolledVerticalRef.current.scrollHeight;
    }
  }, []);

  const sectionId = "roadMapClass";
  const sectionRef = useRef(null);
  const isInViewport1 = useIsInViewport(sectionRef);
  if (isInViewport1) {
    document.getElementById(sectionId).className =
      "section feeBreakDown animate__animated animate__fadeInUp";
  }
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );
    useEffect(() => {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
    return isIntersecting;
  }

  return (
    <>
      <div
        id={sectionId}
        className="section feeBreakDown roadmap-section"
        ref={sectionRef}
      >
        <div className="" id="road-map">
          <div
            className="col-sm-12"
            style={{
              textAlign: "center",
            }}
          >
            <div className="whiteHeading">The Roadmap</div>
            <div className="text roadText">
              With the help of our team and contributors these are the
              milestones we are looking forward to achieve.
            </div>
          </div>
          <Swiper
            ref={roadRef}
            modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
            spaceBetween={0}
            slidesPerView={7}
            breakpoints={{
              1500: {
                slidesPerView: 7,
              },
              1200: {
                slidesPerView: 7,
              },
              760: {
                slidesPerView: 5,
              },
            }}
            initialSlide={roadmap.length - 1}
          >
            {roadmap.map(({ id, img, title, txt }) => {
              return (
                <SwiperSlide
                  key={id}
                  className={
                    id % 2 !== 0
                      ? "d-flex align-items-end position-relative road-swiper"
                      : "position-relative road-swiper"
                  }
                >
                  <div
                    className={`d-flex align-items-center
                      ${
                        id % 2 === 0
                          ? "flex-column-reverse bottom-slide"
                          : "flex-column top-slide"
                      }
                    `}
                  >
                    <img
                      src={img}
                      alt="roadmap"
                      className={(img.indexOf('png') !== -1) ? 'roadmap-image-png' : 'roadmap-image-slide'}
                    />
                    <div
                      key={id}
                      className="roadmap-content d-flex flex-column align-items-center"
                    >
                      <span className="gradColor">{title}</span>
                      <span className="roadmap-body">{txt}</span>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="scrolled-vertical" ref={scrolledVerticalRef}>
            <div className="col-sm-12 roadMapContainer d-flex flex-column">
              <div className="d-flex justify-content-between top-content">
                {roadmap
                  .filter(({ id }) => id % 2 === 0)
                  .map(({ id, title, txt }) => {
                    return (
                      <div
                        key={id}
                        className="roadmap-content d-flex flex-column align-items-center justify-content-end"
                      >
                        <span className="gradColor">{title}</span>
                        <span className="roadmap-body">{txt}</span>
                      </div>
                    );
                  })}
              </div>
              <img
                src={mobileImg}
                alt="Emorya"
                className="roadmap-image road-mobile"
              />
              <div className="d-flex justify-content-between bottomRoadMap">
                {roadmap
                  .filter(({ id }) => id % 2 === 1)
                  .map(({ id, title, txt }) => {
                    return (
                      <div
                        key={id}
                        className="roadmap-content d-flex flex-column align-items-center"
                      >
                        <span className="gradColor">{title}</span>
                        <span className="roadmap-body">{txt}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
