import { useState, useEffect, useCallback } from "react";
import { getFirestore, collection, getCountFromServer } from "firebase/firestore";

import "../Landing.css";
export default function LandingPage({code = null}) {


  

  const db = getFirestore();

async function getTotalUsers() {
  const coll = collection(db, "telegram_users");
  const snapshot = await getCountFromServer(coll);
  console.log("Total users:", snapshot.data().count);
  getUsers(snapshot.data().count);
}



  const [y, setY] = useState(window.scrollY);
  const frameOne = window.innerHeight;
  const [refCode, setRefCode] = useState('e2gjY1EIxdjvoxly');
  var ww;
  var yPos;
  const [usercount, setUserCount] = useState("000000");
  const getUsers = (tgUsers) => {
    try {
      fetch("https://api.emorya.com/total-users")
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          var totalUsers = tgUsers + data.total_users;
          setUserCount(totalUsers.toString().padStart(9, "0"));
          //       setPhotos(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const targetDate = new Date("2024-09-15T23:00:00"); // Your target date
  const calculateTimeLeft = () => {
    const now = new Date();
    const difference = targetDate - now;
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        months: String(
          Math.floor(difference / (1000 * 60 * 60 * 24 * 30))
        ).padStart(2, "0"),
        days: String(
          Math.floor(
            (difference % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
          )
        ).padStart(2, "0"),
        hours: String(
          Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        ).padStart(2, "0"),
        minutes: String(
          Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
        ).padStart(2, "0"),
        seconds: String(Math.floor((difference % (1000 * 60)) / 1000)).padStart(
          2,
          "0"
        ),
      };
    } else {
      timeLeft = {
        months: "00",
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    }
    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      if (y > 0 && y <= frameOne) {
        // eslint-disable-next-line
        ww = (y / frameOne) * 100;
        ww = ww + 50;
        if (ww > 130) {
          ww = 130;
        }
        if (ww < 50) {
          ww = 50;
        }
        ww = ww * -1;
        document.getElementById("emrMonoContainer").style.marginLeft = ww + "%";
      }
      if (y > frameOne && y <= frameOne * 2) {
        // eslint-disable-next-line
        yPos = y - frameOne;
        ww = (yPos / frameOne) * 100;
        console.log(ww);
        ww = 120 - ww;
        document.getElementById("runnerContainer").style.left = ww + "%";
      }
      if (y > frameOne * 2 && y <= frameOne * 3) {
        yPos = y - frameOne;
        ww = (yPos / frameOne) * 100;
        ww = 100 - ww;
        document.getElementById("iconsContainer").style.left = ww + "%";
      }
      if (y > frameOne * 3 && y <= frameOne * 4) {
        yPos = y - frameOne * 3;
        ww = (yPos / frameOne) * 100;
        ww = 100 - ww;
        document.getElementById("code1").style.left = ww + "%";
      }
      if (y > frameOne * 4 && y <= frameOne * 5) {
        yPos = y - frameOne * 4;
        ww = (yPos / frameOne) * 100;
        ww = 100 - ww;
        document.getElementById("code2").style.left = ww + "%";
      }
      if (y > frameOne * 5 && y <= frameOne * 6) {
        yPos = y - frameOne * 5;
        ww = (yPos / frameOne) * 100;
        ww = 100 - ww;
        document.getElementById("code3").style.left = ww + "%";
      }
      setY(window.scrollY);
    },
    [y]
  );
  useEffect(() => {
    if (window.innerWidth > window.innerHeight) {
      window.addEventListener("scroll", handleNavigation);
    }
    return () => {
      if (window.innerWidth > window.innerHeight) {
        window.removeEventListener("scroll", handleNavigation);
      }
    };
  }, [handleNavigation]);
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    getTotalUsers();
    
    return () => clearInterval(timer); // Cleanup the interval on component unmount
    // eslint-disable-next-line
  }, []);


  useEffect(() => {

    if(code != null){
      setRefCode(code);
    }
    // eslint-disable-next-line
  },[])

  return (
    <>
      <div className="section-line-container">
        <div className="section-line">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 20,
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/logotop.png")}
              style={{
                maxWidth: window.innerWidth < window.innerHeight ? "30%" : 150,
                marginTop: 0,
              }}
            />
          </div>
          <img
            alt="Emorya"
            src={require("../assets/landing/QR.png")}
            className="qrBox showDesk"
            style={{
              maxWidth: "12%",
              marginBottom: 20,
            }}
          />
        </div>
      </div>
      <div className="oneDiv section">
        <div
          style={{
            cursor: "pointer",
            position: "absolute",
            top: window.innerWidth < window.innerHeight ? 45 : 60,
            right: window.innerWidth < window.innerHeight ? 30 : 60,
            zIndex: 3000,
            textAlign: "right",
          }}
        >
          <div id="google_translate_element"></div>
          <img
            onClick={() => {
              window.open("https://t.me/EmoryaFinanceInternational", "_blank");
            }}
            alt="Emorya"
            src={require("../assets/landing/telegram-white.png")}
            style={{
              height: window.innerWidth < window.innerHeight ? 36 : 44,
              marginRight: 20,
            }}
          />
          <img
            onClick={() => {
              window.open(
                "https://apps.apple.com/ro/app/emorya/id6449254736",
                "_blank"
              );
            }}
            alt="Emorya"
            src={require("../assets/landing/getapp.png")}
            style={{
              maxWidth: window.innerWidth < window.innerHeight ? "30%" : 150,
              marginTop: 0,
            }}
          />
        </div>
        <div className="backgroundItem ani_slideInLeft">
          <img
            alt="Emorya"
            src={require("../assets/landing/neon.png")}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div
          className="backgroundItem ani_slideInRight"
          style={{
            left: "auto",
            right: 0,
            alignItems: "flex-end",
            paddingTop: "20%",
          }}
        >
          <img
            alt="Emorya"
            src={require("../assets/landing/money.png")}
            style={{
              width: "100%",
            }}
          />
        </div>
        <div className="arziContainer">
          <div className="hugeFont" style={{
            textTransform:'uppercase'
          }}>
          Burn calories and earn money
          </div>
          <img
            alt="Emorya"
            className="arzi"
            src={require("../assets/landing/arzi.png")}
            style={{display:'none'}}
          />
          <br />
          <br />
          <span className="subFont">
             Burn calories and earn rewards, easy and simple, directly on your phone.
          </span>
          <br />
          <br />
          <img
            onClick={() => {
              window.open(
                "https://apps.apple.com/ro/app/emorya/id6449254736",
                "_blank"
              );
            }}
            alt="Emorya"
            src={require("../assets/landing/apple-black.png")}
            style={{
              width: 200,
              margin: 20,
              cursor: "pointer",
            }}
          />
          <img
            onClick={() => {
              window.open(
                "https://play.google.com/store/apps/details?id=com.emorya.emorya_sport_app_flutter",
                "_blank"
              );
            }}
            alt="Emorya"
            src={require("../assets/landing/google-play-black.png")}
            style={{
              width: 200,
              margin: 20,
              cursor: "pointer",
            }}
          />
          <br />
          Use now Emorya refferal code and start earning!
          <br />
          <div
            onClick={() => {
              navigator.clipboard.writeText(refCode);
              alert("Code Copied!");
            }}
            className="coupon"
          >
            <div
              style={{
                float: "right",
              }}
            >
              <i className="far fa-copy"></i>
            </div>
            <strong
              style={{
                fontSize: 15,
              }}
            >
              {refCode}
            </strong>
          </div>
        </div>
        <div
          id="emrMonoContainer"
          className="showDesk"
          style={{
            fontWeight: "bold",
            position: "absolute",
            width: "200%",
            bottom: 20,
            textAlign: "right",
            marginLeft: "-50%",
            opacity: 0.2,
          }}
        >
          <img
            alt="Emorya"
            className="emrMono"
            id="mobileOne"
            src={require("../assets/landing/mono.png")}
            style={{}}
          />
          EMORYA YOUR HEALTH
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div className="col-sm-12 hugeFontSemi">
            How does it work?
            <div
              className="subFont showMobile"
              style={{
                fontWeight: "normal",
                fontSize: 12,
                marginTop: 10,
                borderBottom: 5,
                borderBottomColor: "#BC4FFF",
                borderBottomStyle: "solid",
              }}
            >
              You don't need to be a tech expert or understand how cryptocurrencies work. The app does everything for you.
              <br />
              <br />
            </div>
          </div>
          <div
            className="col-sm-6"
            style={{
              position: "relative",
            }}
          >
            <img
              alt="Emorya"
              className="iphone"
              src={require("../assets/landing/iphone.png")}
            />
          </div>
          <div
            className="col-sm-6"
            style={{
              textAlign:
                window.innerWidth < window.innerHeight ? "center" : "right",
            }}
          >
            <div
              className="subFont showDesk"
              style={{
                fontWeight: "normal",
                fontSize: 30,
                marginTop: 100,
              }}
            >
              You don't need to be a tech expert or understand how cryptocurrencies work. The app does everything for you.
              <span className="showDesk">
                <br />
              </span>
            </div>
            <div className="subFont descarca">
              <span className="showDesk">
                <br />
                <br />
              </span>
              Download Emorya App
            </div>
            <br />
            <br />
            <img
              onClick={() => {
                window.open(
                  "https://apps.apple.com/ro/app/emorya/id6449254736",
                  "_blank"
                );
              }}
              alt="Emorya"
              src={require("../assets/landing/apple-orange.png")}
              style={{
                width: 200,
                marginRight: window.innerWidth < window.innerHeight ? 0 : 20,
                cursor: "pointer",
              }}
            />
            <img
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.emorya.emorya_sport_app_flutter",
                  "_blank"
                );
              }}
              alt="Emorya"
              src={require("../assets/landing/google-orange.png")}
              style={{
                width: 200,
                marginTop: window.innerWidth < window.innerHeight ? 20 : 0,
                cursor: "pointer",
              }}
            />
            <br />
            <br />
            Use Emorya refferal code <strong>
              {refCode}
            </strong>{" "}
            and enter the app.
          </div>
        </div>
      </div>
      <div className="oneDiv section" style={{}}>
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div className="col-sm-12 hugeFontSemi showMobile">
            Connect into the app and start burning calories
            <div
              className="subFont"
              style={{
                fontWeight: "normal",
                fontSize: 12,
                marginTop: 10,
              }}
            >
              Whether you're hitting the gym, running in the park, or working out at home, we track your effort.
              <br />
              <br />
            </div>
          </div>
          <div
            className="col-sm-6 showDesk"
            style={{
              textAlign: "left",
            }}
          >
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
              }}
            >
              Connect into the app and start burning calories
            </div>
            <div className="subFont">
            Whether you're hitting the gym, running in the park, or working out at home, we track your effort.
            </div>
            <br />
          </div>
          <div
            className="col-sm-6"
            style={{
              position: "relative",
            }}
          >
            <div
              id="runnerContainer"
              className="showDesk"
              style={{
                fontWeight: "bold",
                position: "absolute",
                left: "120%",
                top: 0,
              }}
            >
              <img
                alt="Emorya"
                className="showDesk"
                src={require("../assets/landing/running.png")}
                style={{
                  height: "70vh",
                }}
              />
            </div>
            <img
              className="showDesk"
              alt="Emorya"
              src={require("../assets/landing/track-new.png")}
              style={{
                maxWidth: "100%",
              }}
            />
            <img
              className="showMobile"
              alt="Emorya"
              src={require("../assets/landing/runnermobile.png")}
              style={{
                width: "110%",
                marginTop: 30,
              }}
            />
          </div>
        </div>
        <div
          id="iconsContainer"
          className="iconsContainer showDesk"
          style={{}}
        ></div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-5 hugeFont showDesk"
            style={{
              textAlign: "left",
              textTransform: "uppercase",
            }}
          >
            Why to
          </div>
          <div
            className="col-sm-4"
            style={{
              textAlign: "left",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/goals.png")}
              style={{
                maxWidth:
                  window.innerWidth < window.innerHeight ? "100%" : "70%",
                marginTop: 30,
              }}
            />
            <div
              className="subFont"
              style={{
                textAlign: "left",
                marginTop: 10,
              }}
            >
              {usercount}+ Users using app.
            </div>
          </div>
          <div
            className="col-sm-12 hugeFont"
            style={{
              textAlign: "left",
              textTransform: "uppercase",
            }}
          >
            <span className="showMobile">
              <br />
              Why to
            </span>
            use Emorya?
          </div>
          <div
            className="col-sm-12 subFont"
            style={{
              textAlign: "center",
              fontSize: window.innerWidth < window.innerHeight ? 15 : 35,
              fontWeight: "normal",
            }}
          >
            KEY POINTS
          </div>
          <div
            className="col-sm-12 subFont"
            style={{
              textAlign: "center",
              fontWeight: "normal",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 50,
              fontSize: window.innerWidth < window.innerHeight ? 13 : 25,
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/star.png")}
              style={{
                height: 30,
              }}
            />
            &nbsp;&nbsp; 1852+ 5 Star App Review
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-4"
            style={{
              textAlign:
                window.innerWidth < window.innerHeight ? "center" : "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="hugeFontSemi showMobile">Easy to use</div>
            <div className="mediumFont showDesk">Easy to use</div>
            <div className="subFont">
              <span className="showMobile">
                <br />
              </span>
              No technical knowledge is required. All you need to do is move and burn calories. We’ll take care of the rest.
              <span className="showMobile">
                <br />
              </span>
            </div>
          </div>
          <div className="col-sm-4">
            <img
              alt="Emorya"
              src={require("../assets/landing/mobile1.png")}
              style={{
                maxWidth: "100%",
              }}
            />
          </div>
          <div
            className="col-sm-4 midCol"
            style={{
              borderWidth: 0,
              position: "relative",
            }}
          >
            <img
              id="code1"
              className="showDesk"
              alt="Emorya"
              src={require("../assets/landing/code1.png")}
              style={{
                maxWidth: "100%",
                position: "absolute",
                top: "20%",
                left: "120%",
              }}
            />
            <img
              className="showMobile"
              alt="Emorya"
              src={require("../assets/landing/code1.png")}
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-4 midCol"
            style={{
              borderWidth: 0,
            }}
          >
            <img
              id="code2"
              className="showDesk"
              alt="Emorya"
              src={require("../assets/landing/code2.png")}
              style={{
                maxWidth: "100%",
                position: "absolute",
                top: "20%",
                left: "-120%",
              }}
            />
          </div>
          <div
            className="col-sm-4 showMobile"
            style={{
              textAlign: "center",
            }}
          >
            <div
              className="mediumFont"
              style={{
                textAlign: "center",
              }}
            >
              Real rewards
            </div>
            <br />
            <div className="subFont">
            Your burned calories turn into money. It's the perfect way to stay motivated!
            </div>
            <br />
            <br />
          </div>
          <div className="col-sm-4">
            <img
              alt="Emorya"
              src={require("../assets/landing/mobile1.png")}
              style={{
                maxWidth: "100%",
              }}
            />
          </div>
          <div
            className="col-sm-4 showDesk"
            style={{
              textAlign: "left",
              display: window.innerWidth < window.innerHeight ? "none" : "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="mediumFont">Real Rewards</div>
            <div className="subFont">
            Your burned calories turn into money. It's the perfect way to stay motivated!
            </div>
          </div>
          <div className="col-sm-4 showMobile">
            <img
              className="showMobile"
              alt="Emorya"
              src={require("../assets/landing/code2.png")}
              style={{
                width: "100%",
                marginTop: 50,
                marginBottom: 50,
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-8"
            style={{
              textAlign:
                window.innerWidth < window.innerHeight ? "left" : "left",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <div className="mediumFont">Questions</div>
            <span className="showMobile">
              <br />
              <br />
            </span>
            <div
              style={{
                fontSize: 15,
              }}
            >
              <ul
                style={{
                  listStyle: "decimal",
                  padding: 0,
                  paddingLeft: window.innerWidth < window.innerHeight ? 0 : 40,
                }}
              >
                <li>
                  <strong>How to buy Emorya token?.</strong>
                  <br />
                  Here is an easy tutorial on how to buy Emorya:
                  <br />
                  https://docs.emorya.com/docs/howtos/xportal
                </li>
                <li
                  style={{
                    paddingTop: 20,
                  }}
                >
                  <strong>Where is the money coming from burned calories?</strong>
                  <br />
                  The money comes from the utility of the base currency and our hyper-deflationary ecosystem, which becomes increasingly scarce and valuable with each transaction. The app will generate multiple revenue streams, and the project aims to reinvest 50% consistently to create ongoing demand and scarcity.
                </li>
                <li
                  style={{
                    paddingTop: 20,
                  }}
                >
                  <strong>Does it cost anything?</strong>
                  <br />
                  You will have access to all the features and you will be able to earn rewards just by burning calories.
                </li>
                <li
                  style={{
                    paddingTop: 20,
                  }}
                >
                  <strong>
                    In how much time I can generate money if I download the app?
                  </strong>
                  <br />
                  You can start generating money within the first hours of downloading the app. You just need to have a minimum amount of EMR 10 EMR, which you deposit in dynamic staking, then you can burn calories and begin monetizing within the first hours, thanks to the specially designed economic model aimed at revolutionizing the economy.
                </li>
              </ul>
            </div>
          </div>
          <div
            className="col-sm-4"
            style={{
              display: "none",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/mobile1.png")}
              style={{
                maxWidth: "100%",
              }}
            />
          </div>
          <div
            className="col-sm-4 midCol"
            style={{
              borderWidth: 0,
            }}
          >
            <img
              id="code3"
              className="showDesk"
              alt="Emorya"
              src={require("../assets/landing/code3.png")}
              style={{
                maxWidth: "100%",
                position: "absolute",
                top: "20%",
                left: "120%",
              }}
            />
            <img
              className="showMobile"
              alt="Emorya"
              src={require("../assets/landing/code3.png")}
              style={{
                width: "100%",
              }}
            />
          </div>
        </div>
      </div>
      <div
        className="oneDiv section "
        style={{
          minHeight:
            window.innerWidth < window.innerHeight
              ? window.innerWidth
              : "100vh",
        }}
      >
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div className="col-sm-12 showDesk">
            <iframe
              style={{
                width: (window.innerHeight * 0.8 * 560) / 315,
                height: window.innerHeight * 0.8,
                borderWidth: 10,
                borderColor: "#fff",
                borderStyle: "solid",
                borderRadius: 5,
              }}
              src="https://www.youtube.com/embed/vFpmgP6YPPY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
          <div
            className="col-sm-12 showMobile"
            style={{
              width: "100%",
              overflow: "hidden",
              padding: 0,
            }}
          >
            <iframe
              style={{
                height: (window.innerWidth * 0.9 * 315) / 560,
                width: window.innerWidth * 0.8,
                borderWidth: 2,
                borderColor: "#fff",
                borderStyle: "solid",
                borderRadius: 5,
              }}
              src="https://www.youtube.com/embed/vFpmgP6YPPY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-6 textCenter"
            style={{
              borderWidth: 0,
              textAlign: "left",
            }}
          >
            <div
              className="mediumFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 30 : 60,
              }}
            >
              Where is the money coming from burned calories??
            </div>
            <br />
            The money comes from the utility of the base currency and our hyper-deflationary ecosystem, which becomes increasingly scarce and valuable with each transaction.
            <br />
            <br />
            The app will generate multiple revenue streams, and the project aims to reinvest 50% consistently to create ongoing demand and scarcity.
          </div>
          <div
            className="col-sm-6"
            style={{
              padding: 0,
              textAlign: "center",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/slide1.png")}
              style={{
                maxWidth: "100%",
                marginTop: window.innerWidth < window.innerHeight ? 40 : 0,
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-6 textCenter"
            style={{
              borderWidth: 0,
              textAlign: "left",
            }}
          >
            <div
              className="mediumFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 30 : 60,
              }}
            >
              How much does the app cost?
            </div>
            <br />
           Downloanding the app is free! 
            <br />
            <br />
            You will have access to all the features and you will be able to earn rewards just by burning calories.
          </div>
          <div
            className="col-sm-6"
            style={{
              textAlign: "center",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/slide2.png")}
              style={{
                maxWidth: "100%",
                marginTop: window.innerWidth < window.innerHeight ? 40 : 0,
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-6 textCenter"
            style={{
              borderWidth: 0,
              textAlign: "left",
            }}
          >
            <div
              className="mediumFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 30 : 60,
              }}
            >
              In how much time I can generate money if I download the app?
            </div>
            <br />
            You can start generating money within the first hours of downloading the app. You just need to have a minimum amount of 10 EMR, which you deposit in dynamic staking, then you can burn calories and begin monetizing within the first hours, thanks to the specially designed economic model aimed at revolutionizing the economy.
          </div>
          <div className="col-sm-6">
            <img
              alt="Emorya"
              src={require("../assets/landing/slide3.png")}
              style={{
                maxWidth: "100%",
                marginTop: window.innerWidth < window.innerHeight ? 40 : 0,
              }}
            />
          </div>
        </div>
      </div>
      <div className="oneDiv section">
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div
            className="col-sm-6"
            style={{
              textAlign: "left",
              borderBottomWidth: 1,
              borderBottomColor: "#fff",
              borderBottomStyle: "solid",
            }}
          >
            <div
              className="showMobile"
              style={{
                float: "right",
              }}
            >
              <img
                alt="Emorya"
                src={require("../assets/landing/avatar.png")}
                style={{
                  width: 100,
                }}
              />
            </div>
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
                fontSize: window.innerWidth < window.innerHeight ? 25 : 60,
              }}
            >
              Member
              <br />
              reviews
            </div>
            <div
              className="subFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 12 : 30,
              }}
            >
              What does members say about the app?
              <br />
              <br />
            </div>
          </div>
          <div
            className="col-sm-6 showDesk"
            style={{
              borderBottomWidth: 1,
              borderBottomColor: "#fff",
              borderBottomStyle: "solid",
              position: "relative",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/avatar.png")}
              style={{
                maxWidth: "60%",
                position: "absolute",
                left: 0,
                bottom: -40,
              }}
            />
          </div>
          <div
            className="col-sm-6"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "40vh",
            }}
          >
            <img
              alt="Emorya"
              src={require("../assets/landing/testi.png")}
              style={{
                maxWidth:
                  window.innerWidth < window.innerHeight ? "80%" : "30%",
              }}
            />
          </div>
          <div
            className="col-sm-6"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            <div
              className="subFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 12 : 22,
                fontWeight: "normal",
              }}
            >
              I believe this is a revolutionary project! The idea of being able to burn calories and earn money with just a few clicks is extraordinary! When the app launches and you see that the effort you put in turns into money, I believe it will be something truly revolutionary!
            </div>
          </div>
        </div>
      </div>
      <div
        className="oneDiv section"
        style={{
          display: "none",
        }}
      >
        <div
          className="row"
          style={{
            width: "90%",
            minHeight: "90vh",
          }}
        >
          <div className="col-sm-12">
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
                fontSize: window.innerWidth < window.innerHeight ? 25 : 60,
                textAlign: "center",
                paddingBottom: 40,
              }}
            >
              Lansăm în
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="timerContainer">
              <div className="timer">{String(timeLeft.days).charAt(0)}</div>
              <div className="timer">{String(timeLeft.days).charAt(1)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
                fontSize: 15,
              }}
            >
              Days
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="timerContainer">
              <div className="timer">{String(timeLeft.hours).charAt(0)}</div>
              <div className="timer">{String(timeLeft.hours).charAt(1)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
                fontSize: 15,
              }}
            >
              Hours
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="timerContainer">
              <div className="timer">{String(timeLeft.minutes).charAt(0)}</div>
              <div className="timer">{String(timeLeft.minutes).charAt(1)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
                fontSize: 15,
              }}
            >
              Minutes
            </div>
          </div>
          <div className="col-sm-3 col-6">
            <div className="timerContainer">
              <div className="timer">{String(timeLeft.seconds).charAt(0)}</div>
              <div className="timer">{String(timeLeft.seconds).charAt(1)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
                fontSize: 15,
              }}
            >
              Seconds
            </div>
          </div>
          <div className="col-sm-12">
            <div
              className="mediumFont"
              style={{
                fontSize: window.innerWidth < window.innerHeight ? 15 : 30,
                textAlign: "center",
                marginTop: 50,
                fontWeight: "normal",
              }}
            >
              Be among the first who uses the app and take part in a revolutionary journey!
            </div>
          </div>
        </div>
      </div>
      <div
        className="oneDiv section"
        style={{
          minHeight: window.innerWidth < window.innerHeight ? "70vh" : "90vh",
        }}
      >
        <div
          className="row"
          style={{
            width: "90%",
          }}
        >
          <div className="col-sm-12">
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
                fontSize: window.innerWidth < window.innerHeight ? 25 : 60,
                textAlign: "center",
                paddingBottom: 40,
              }}
            >
              Real time users
            </div>
          </div>
          <div className="col-sm-12 col-12">
            <div className="timerContainer userTimer">
              <div className="timer">{String(usercount).charAt(0)}</div>
              <div className="timer">{String(usercount).charAt(1)}</div>
              <div className="timer">{String(usercount).charAt(2)}</div>
              <div className="timer">{String(usercount).charAt(3)}</div>
              <div className="timer">{String(usercount).charAt(4)}</div>
              <div className="timer">{String(usercount).charAt(5)}</div>
              <div className="timer">{String(usercount).charAt(6)}</div>
              <div className="timer">{String(usercount).charAt(7)}</div>
              <div className="timer">{String(usercount).charAt(8)}</div>
            </div>
            <div
              className="subFont"
              style={{
                textTransform: "uppercase",
              }}
            >
              Users currently live on app.
            </div>
          </div>
        </div>
      </div>
      <div className="oneDiv section" style={{}}>
        <div
          className="row"
          style={{
            width: window.innerWidth < window.innerHeight ? "100%" : "90%",
          }}
        >
          <div className="col-sm-2"></div>
          <div
            className="col-sm-8"
            style={{
              textAlign: "center",
            }}
          >
            <div
              className="mediumFont"
              style={{
                textTransform: "uppercase",
                textAlign: "center",
                fontSize: window.innerWidth < window.innerHeight ? 30 : 60,
              }}
            >
              Download the app
              <br />
            </div>
            <div
              className="subFont"
              style={{
                fontWeight: "normal",
              }}
            ></div>
            <br />
            <img
              alt="Emorya"
              src={require("../assets/landing/challange1.png")}
              style={{
                maxWidth:
                  window.innerWidth < window.innerHeight ? "100%" : "80%",
              }}
            />
            <br />
            <br />
            <img
              onClick={() => {
                window.open(
                  "https://apps.apple.com/ro/app/emorya/id6449254736",
                  "_blank"
                );
              }}
              alt="Emorya"
              src={require("../assets/landing/apple-orange.png")}
              style={{
                width: 200,
                cursor: "pointer",
              }}
            />
            <img
              onClick={() => {
                window.open(
                  "https://play.google.com/store/apps/details?id=com.emorya.emorya_sport_app_flutter",
                  "_blank"
                );
              }}
              alt="Emorya"
              src={require("../assets/landing/google-orange.png")}
              style={{
                width: 200,
                marginLeft: window.innerWidth < window.innerHeight ? 0 : 20,
                marginTop: window.innerWidth < window.innerHeight ? 20 : 0,
                cursor: "pointer",
              }}
            />
            <br />
            <br />
            <div id="google_translate_element"></div>
            Use Emorya refferal code <strong>
              {refCode}
            </strong>{" "}
            and enter the app.
            <div
              style={{
                width: 350,
                cursor: "pointer",
              }}
              onClick={() => {
                window.open(
                  "https://t.me/EmoryaFinanceInternational",
                  "_blank"
                );
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
