import { useRef } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

import breakdown1 from "../assets/breakdown-1.svg";
import breakdown2 from "../assets/breakdown-2.svg";
import breakdown3 from "../assets/breakdown3.png";
import breakdown4 from "../assets/breakdown-4-1.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "../App.css";

export default function FeeBreakDownMobile() {
  const swiperRef = useRef(null);
  return (
    <div
      className="showOnMobile"
      style={{
        marginBottom: 50,
      }}
    >
      <Swiper
        ref={swiperRef}
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        spaceBetween={0}
        slidesPerView={1.9}
        scrollbar={{ draggable: true }}
      >
        <SwiperSlide>
          <div className="BreakContainer">
            <img
              alt="Emorya"
              src={breakdown1}
              style={{
                width: "100%",
              }}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="BreakContainer">
            <img
              alt="Emorya"
              src={breakdown2}
              style={{
                width: "100%",
              }}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="BreakContainer">
            <img
              alt="Emorya"
              src={breakdown3}
              style={{
                width: "100%",
              }}
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="BreakContainer">
            <img
              alt="Emorya"
              src={breakdown4}
              style={{
                width: "100%",
              }}
            />
          </div>
        </SwiperSlide>
      </Swiper>
      <div
        style={{
          textAlign: "right",
          padding: 20,
          fontSize: 20,
          color: "#41faa4",
        }}
        onClick={() => {
          swiperRef.current.swiper.slideNext();
        }}
      >
        <i className="fas fa-arrow-right"></i>
      </div>
    </div>
  );
}
