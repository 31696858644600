import nftBoxs from "../assets/what-is-emorya/nfts-utility.svg";
import playImg from "../assets/what-is-emorya/play.svg";
import globalImg from "../assets/what-is-emorya/global.svg";
import p2Img from "../assets/what-is-emorya/p2e.svg";
import hyperImg from "../assets/what-is-emorya/hyper.svg";
import staticImg from "../assets/what-is-emorya/static.svg";

export const whatIsEmoryaBoxs = [
  {
    id: 0,
    label: "NFTs\n Utility",
    img: nftBoxs,
  },
  {
    id: 1,
    bottomText: "How it works",
    img: playImg,
  },
  {
    id: 2,
    label: "Global\n Platform",
    img: globalImg,
  },
  {
    id: 3,
    label: "P2E\n Mining",
    img: p2Img,
  },
  {
    id: 4,
    label: "Hyper\n Deflationary\n Token",
    img: hyperImg,
  },
  {
    id: 5,
    label: "Static\n Rewards",
    img: staticImg,
  },
];

export const sports = [
  {
    id: 0,
    title: "Your Own  Web3 lifestyle",
    text: "Being part of a Web3 app with millions, to embrace a healthier lifestyle, Social-Fi and Game-Fi elements by creating your own crypto earnings are stored in the in-app Wallet, which has a built-in Swap function.",
  },
  {
    id: 1,
    title: "Activate Infrastructure",
    text: "Embark on an opportunity to own $EMR tokens and participate in our rewards program. By committing to our staking program, you can engage in the BurnOut process for your calories and earn $EMRS tokens.",
  },
  {
    id: 2,
    title: "Dapp System Control",
    text: "A sophisticated suite of applications, meticulously crafted by experts, empowering you to establish your aspirations, take charge of your outcomes, and oversee your well-being.",
  },
];

export const roadmap = [
  {
    id: 0,
    img: "/roadmap/road-1.svg",
    title: "Technical Paper",
    txt: "We start to build Emorya technical paper. To get our team around the project vision. To build a strategy for implementation.",
  },
  {
    id: 1,
    title: "Tech Progress",
    img: "/roadmap/road-2.svg",
    txt: "Application work, advisors meetings, website competition, first private sell, white paper update.",
  },
  {
    id: 2,
    title: "Investors",
    img: "/roadmap/road-3.svg",
    txt: "More private sales, white paper update, media meets Emorya, event presentation, legal opinion audit.",
  },
  {
    id: 3,
    title: "Meet $EMR On Blockchain",
    img: "/roadmap/road-4.svg",
    txt: "First pre-sale, $EMRs meets the MVX, first tokens in wallet, started marketing, communities building, events and preparing for the second presale.",
  },
  {
    id: 4,
    title: "Winter Fever",
    img: "/roadmap/road-5.svg",
    txt: "Second presale. Early test phase of Emorya Sprots App. Developing strategy for user programs. AirDrop for $EMRS-Emorya Sports Token. Staking and mining programs.",
  },
  {
    id: 5,
    title: "dApps",
    img: "/roadmap/road-6.svg",
    txt: "Implement the strategy for the ecosystem, active economy and utility. Start to develop DApps provide to functions and utility to $EMR and $EMRS users. First partners announcement. ",
  },
  {
    id: 6,
    title: "Acceleration",
    img: "/roadmap/road-7.svg",
    txt: "NFT’s programs for users mining $EMRS sector and launching EMORYA dApps to accelerate adoption and integrate tokens in a scalable economy.  ",
  },
  {
    id: 7,
    title: "Expansion",
    img: "/roadmap/road-8.svg",
    txt: "Scaling the Proof of Concept move towards the next step in the designing a suitable rol-out strategy. Test and improve app with community. Reveal partnerships and new ambasadors. ",
  },
  {
    id: 8,
    title: "$EMR Launchpad",
    img: "/roadmap/road-9.svg",
    txt: "EMORYA team and partners will start the process to list and program the launch of the economy. $EMR-Launchpad process fpr the listing the coins on market.",
  },
  {
    id: 9,
    title: "Redesign Website",
    img: "/roadmap/road-10.svg",
    txt: "Upscalling project means new features and new operative apps. The process needs to accord the site design with the new expansion of Emorya economy.",
  },
  {
    id: 10,
    title: "$EMRS Listing Emorya Sports X-Pro",
    img: "/roadmap/road-11.svg",
    txt: "Emorya Sports Token ($EMRS) is set to be listed in the Emorya ecosystem, activating a robust economic flux that implements a hyperdeflationary process where burning calories equates to mining $EMRS tokens.",
  },
  {
    id: 11,
    title: "Optimization",
    img: "/roadmap/road-14.png",
    txt: "Optimizing the application, simplifying the application for Web2 users.",
  },
  {
    id: 12,
    title: "Partnerships",
    img: "/roadmap/road-15.png",
    txt: "Preparation for massive adoption and new partnerships announcements.",
  },
  {
    id: 13,
    title: "Artificial Intelligence",
    img: "/roadmap/road-14.png",
    txt: "Fundraising & Integrating AI Agents.",
  },
  {
    id: 14,
    title: "New listings",
    img: "/roadmap/road-15.png",
    txt: "New listings",
  },
  {
    id: 15,
    title: "Next Level",
    img: "/roadmap/road-16.png",
    txt: "Preparing for Emorya next level",
  },

  // {
  //   id: 11,
  //   title: "Emorya Mobile App NFT marketplace.",
  //   img: "/roadmap/road-12.svg",
  //   txt: "The launch of the EMORYA NFT MARKETPLACE introduces a new market opening, brining an accelerated economy and activatin exciting new features and dynamics within the ecosystem,icluding trading,sharing and developing features that present fresh challenges and opportunities.",
  // },
  // {
  //   id: 12,
  //   title: "Emorya BETX",
  //   img: "/roadmap/road-13.svg",
  //   txt: "The upcoming platform dedicated to EMORYA SPORTS $EMRS will offer the opportunity to engage in the sports betting and participate in challanges, providing a platform specially designed to win more $EMRS tokens.",
  // },
];

export const tokenBox = [
  {
    id: 0,
    percent: "30%",
    txt: "Used tp secure the Emorya ecosystem and as liquidity for EmoryaSports token.",
    color: "#3EF8A7",
  },
  {
    id: 1,
    percent: "24%",
    txt: "Pre-Sales",
    color: "#00CDF5",
  },
  {
    id: 2,
    percent: "20%",
    txt: "Public Sale",
    color: "#0865FC",
  },
  {
    id: 3,
    percent: "10%",
    txt: "Marketing & Innovation",
    color: "#6F3DD3",
  },
  {
    id: 4,
    percent: "10%",
    txt: "Core Team",
    color: "#5F13F5",
  },
  {
    id: 5,
    percent: "6%",
    txt: "Private Sale",
    color: "#330CA5",
  },
];
