import "firebase/auth"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { getDatabase, ref, set,onValue,push,query,orderByChild,equalTo } from "firebase/database";




const firebaseConfig = {

    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: "emorya-gcp.firebaseapp.com",
    projectId: "emorya-gcp",
    storageBucket: "emorya-gcp.appspot.com",
    messagingSenderId: "177375814000",
    appId: "1:177375814000:web:327b4e2e55f590e81aefb5",
    databaseURL: "https://emorya-gcp-default-rtdb.europe-west1.firebasedatabase.app",


};


 
const fApp = firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
//let database = firebase.database();
//const app = initializeApp(firebaseConfig);
const db = getDatabase(fApp);



export { auth, firebase,db,ref ,set,onValue,push,query,orderByChild,equalTo};